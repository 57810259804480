import "./App.css";
import React, { useRef } from "react";
import { Link } from "react-router-dom";
import logo from "../src/images/vslogo_new.png" // Adjust the path if necessary
import lg from "../src/images/menu.gif" // Adjust the path if necessary

const Head = () => {
  const navRef = useRef(null);

  const handleMenuToggle = () => {
    if (window.innerWidth <= 568) {
      navRef.current.classList.toggle("show");
    }
  };

  return (
    <header className="App-header">
      <div className="header-left">
        <img src={logo} className="App-logo" alt="logo" />
      </div>
      <div>
        <p style={{ color: "white", margin: 0 }}>
          <a href="tel:+919095422237" style={{ textDecoration: "none", color: "inherit" }}>
            <i className="fa fa-phone" style={{ height: "4rem", width: "4rem" }}></i>
          </a>
          <a href="mailto:vsoftsolutions8813@gmail.com" style={{ textDecoration: "none", color: "inherit" }}>
            <i className="fa fa-envelope" style={{ height: "4rem", width: "4rem" }}></i>
          </a>
        </p>
      </div>
      <button className="menu-button" onClick={handleMenuToggle}>
        <img src={lg} alt="Menu" className="menu-gif" />
      </button>

      <nav className="App-nav" ref={navRef}>
        <ul>
          <li>
            <Link to="/home">
              <ion-icon name="home-outline"></ion-icon>
              <h6 className="custom-heading1">Vsflows</h6>
            </Link>
          </li>
          <li>
            <Link to="/services">
              <ion-icon name="add-circle-outline"></ion-icon>
              <h6 className="custom-heading2">Services</h6>
            </Link>
          </li>
          <li>
            <Link to="/creation">
              <ion-icon name="settings-outline"></ion-icon>
              <h6 className="custom-heading1">Creations</h6>
            </Link>
          </li>
          <li>
            <Link to="/about">
              <ion-icon name="chatbubble-outline"></ion-icon>
              <h6 className="custom-heading2">About</h6>
            </Link>
          </li>
          <li>
            <Link to="/contact">
              <ion-icon name="person-outline"></ion-icon>
              <h6 className="custom-heading1">Contact</h6>
            </Link>
          </li>
          <li>
            <Link to="/career">
              <ion-icon name="home-outline"></ion-icon>
              <h6 className="custom-heading1">Career</h6>
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Head;
